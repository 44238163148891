<template>
  <div class="services">
    <!-- 服务链订单，tab切换不同服务 -->
    <div class="services_tabs" v-if="isChain">
      <el-tabs class="service-el-tabs" v-model="$activeService" @tab-click="onTabClick">
        <el-tab-pane
          v-for="(item, index) in tabs"
          :key="index"
          :label="item.title"
          :name="`${index}`"
          :disabled="userInfo.id !== order.buyerId && userInfo.organizationId !== item.orgId">
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 服务订单详情 -->
    <div class="services_item" v-if="JSON.stringify(serviceData) !== '{}'">
      <div class="services_item_left">
        <div class="user">
          <order-title title="供方基本信息" />
          <div class="user_item tw-text-14px">
            <p class="user_item_label">供方：</p>
            <div class="user_item_value">
              <p class="org">
                {{seller.organizationName}}
              </p>
            </div>
          </div>
          <div class="user_item tw-text-14px">
            <p class="user_item_label">联系人：</p>
            <p class="user_item_value">{{seller.username}}</p>
          </div>
          <div class="user_item tw-text-14px">
            <p class="user_item_label">联系方式：</p>
            <p class="user_item_value">{{seller.mobile}}</p>
          </div>
          <div class="user_item tw-text-14px">
            <p class="user_item_label">订单类型：</p>
            <p class="user_item_value">
              {{{service_chain: '服务链订单', service: '服务订单'}[order.orderType]}}
            </p>
          </div>
          <div class="user_item tw-text-14px">
            <p class="user_item_label">服务类型：</p>
            <p class="user_item_value">{{service.serviceTypeName}}</p>
          </div>
          <div class="user_item tw-text-14px">
            <p class="user_item_label">服务价格（元）：</p>
            <p class="user_item_value tw-font-bold" style="color:#FF4343;">{{service.fee}}</p>
          </div>
        </div>
        <div class="user">
          <order-title title="需方基本信息" />
          <div class="user_item tw-text-14px">
            <p class="user_item_label">联系人：</p>
            <p class="user_item_value">{{buyer.username}}</p>
          </div>
          <div class="user_item tw-text-14px">
            <p class="user_item_label">联系方式：</p>
            <p class="user_item_value">{{buyer.mobile}}</p>
          </div>
        </div>
      </div>
      <div class="services_item_right">
        <div class="process">
          <order-title title="服务流程信息" />
          <div class="process_main">
            <el-steps
              direction="vertical"
              :active="activeProgress"
              finish-status="success">
              <el-step
                v-for="(item, index) in progress"
                :key="item.id">
                <template slot="title">
                  <div class="process_title">
                    <p class="process_title_name">
                      {{item.name}}
                    </p>
                    <p class="process_title_line"></p>
                    <template v-if="index === 0">
                      <!-- 卖家 -->
                      <template v-if="isSeller">
                        <el-button
                          v-if="item.status === 0"
                          size="small"
                          type="primary"
                          @click="onCheck(index)">
                          上传合同
                        </el-button>
                        <button
                          v-else
                          class="process_title_btn"
                          @click="onCheck(index)">
                          查看详情
                        </button>
                      </template>
                      <template v-if="isBuyer">
                        <button
                          class="process_title_btn"
                          @click="onCheck(index)">
                          {{ item.status === 0 ? '待提交' : '查看详情' }}
                        </button>
                      </template>
                    </template>
                    <template v-else-if="index === progress.length - 1">
                      <template v-if="isSeller">
                        <el-button
                          v-if="item.status === 0"
                          size="small"
                          type="primary"
                          @click="onCheck(index)">
                          上传交付物
                        </el-button>
                        <button
                          v-else
                          class="process_title_btn"
                          @click="onCheck(index)">
                          查看详情
                        </button>
                      </template>
                      <template v-if="isBuyer">
                        <button
                          class="process_title_btn"
                          @click="onCheck(index)">
                          {{ item.status === 0 ? '待提交' : '查看详情' }}
                        </button>
                      </template>
                    </template>
                    <button
                      v-else
                      class="process_title_btn"
                      @click="onCheck(index)">
                      查看详情
                    </button>
                  </div>
                </template>
              </el-step>
            </el-steps>
          </div>
        </div>
      </div>
    </div>

    <!-- 服务流程详情 -->
    <el-dialog
      title="流程详情"
      width="600px"
      :visible.sync="dialogVisible"
      :show-close="false">
      <custom-form
        v-if="checkIndex > -1"
        ref="customForm"
        :mode="mode"
        :formModel="fieldList"
        :formData="formData">
        <custom-form-item
          v-for="item in fieldList"
          :key="item.column"
          :item="item"
          :value.sync="formData[item.column]">
        </custom-form-item>
      </custom-form>

      <div slot="footer">
        <el-button
          size="small"
          @click="onCancel">
          取消
        </el-button>
        <el-button
          v-if="mode === 'enter'"
          type="primary"
          size="small"
          @click="onSubmit">
          提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from '@/api'

import orderTitle from './order-title'
import customForm from '@/components/custom-form/index'
import customFormItem from '@/components/custom-form/custom-form-item'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    orderTitle,
    customForm,
    customFormItem
  },
  props: {
    order: Object,
    activeService: String // 标记当前选择的服务
  },
  data () {
    return {
      serviceData: {}, // 当前选择的服务相关数据
      dialogVisible: false,
      checkIndex: -1,
      mode: '',
      fieldList: [],
      formData: {}
    }
  },
  computed: {
    $activeService: {
      get () {
        return this.activeService
      },
      set (val) {
        this.$emit('update:activeService', val)
      }
    },
    // 是否是服务链订单
    isChain () {
      return this.order.orderType === 'service_chain' || false
    },
    // tab栏
    tabs () {
      return this.order.tabs
    },
    // 当前服务的买家
    buyer () {
      return this.serviceData.buyer
    },
    isBuyer () {
      return this.userInfo.id === this.buyer.id
    },
    // 当前服务的卖家
    seller () {
      return this.serviceData.seller
    },
    isSeller () {
      return this.userInfo.id === this.seller.id
    },
    // 当前服务
    service () {
      return this.serviceData.service
    },
    serviceId () {
      return this.order.tabs[this.$activeService].id
    },
    // 服务流程
    progress () {
      return this.serviceData.progress
    },
    activeProgress () {
      let activeIndex = 0
      for (let index = 0; index < this.progress.length; index++) {
        const element = this.progress[index]
        if (element.status === 0) {
          activeIndex = index
          break
        }
        if (index === this.progress.length - 1) {
          activeIndex = index + 1
        }
      }
      return activeIndex
    }
  },
  mounted () {
    this.getServiceData()
  },
  methods: {
    onTabClick () {
      this.$nextTick(() => {
        console.log('tab-click', this.activeService)
        this.getServiceData()
      })
    },
    getServiceData (serviceId = this.serviceId) {
      api.getOrderServiceDetail(this.order.id, {
        serviceId
      }).then(res => {
        if (res.data.code === 0) {
          this.serviceData = {
            ...res.data.data
          }
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onCheck (index) {
      if (this.isBuyer) {
        this.mode = 'static'
      }
      if (this.isSeller) {
        if (this.order.orderType === 'service' && this.order.logicStatus === globalVar.ORDER_STATUS_0) {
          this.$message.warning('当前订单需被确认')
          return
        }
        if (this.order.orderType === 'service' && this.order.logicStatus === globalVar.ORDER_STATUS_4) {
          this.$message.warning('当前订单已被取消')
          return
        }
        if (index > this.activeProgress) {
          this.$message.warning('未进行到当前流程')
          return
        }
        if (this.progress[index].status === 0) {
          this.mode = 'enter'
        } else {
          this.mode = 'static'
        }
      }

      if (this.order.logicStatus === this.globalVar.ORDER_STATUS_2) {
        this.mode = 'static'
      }

      this.fieldList = this.$clone(this.progress[index].fields)
      this.formData = this.$clone(this.progress[index].data)

      this.checkIndex = index
      this.dialogVisible = true
    },
    onCancel () {
      this.checkIndex = -1
      this.mode = ''
      this.fieldList = []
      this.formData = {}
      this.dialogVisible = false
    },
    async onSubmit () {
      try {
        const data = await this.$refs.customForm.validate()

        const submitData = {
          orderId: this.order.id,
          serviceId: this.service.id,
          processId: this.progress[this.checkIndex].processId,
          data
        }

        api.submitProcessData({
          ...submitData
        }).then(res => {
          if (res.data.code === 0) {
            this.getServiceData()
            this.$message.success('流程表单已提交')
            this.onCancel()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('请求出错')
        })
      } catch (error) {
        console.log(error)
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.services {
  width: 1200px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 6px;
  overflow: hidden;
  &_tabs {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  }
  &_item {
    position: relative;
    padding: 20px 0 30px 0;
    display: flex;
    &_left {
      width: 362px;
      padding: 0 30px;
    }
    &_right {
      flex: 1;
      padding: 0 120px 0 30px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 20px;
      bottom: 30px;
      left: 362px;
      width: 1px;
      border-left: 1px solid #DCDFE6;
    }
  }
}
.user {
  &_item {
    display: flex;
    padding: 5px 0;
    min-height: 30px;
    margin-bottom: 10px;
    &_label {
      width: 130px;
      line-height: 20px;
      padding-left: 10px;
      color: #909399;
    }
    &_value {
      flex: 1;
      line-height: 20px;
      color: #303133;
      .org {
        & > i {
          margin: 2px 0 2px 10px;
          color: #3473E6;
        }
      }
    }
  }
}
.process {
  &_title {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    &_name {
      line-height: 20px;
      font-weight: bold;
    }
    &_line {
      flex: 1;
      height: 1px;
      margin: 0 20px;
      border-top: 1px dashed #DCDFE6;
    }
    &_btn {
      cursor: pointer;
      color: #909399;
      border-color: transparent;
      background: transparent;
      &:hover, &:focus {
        opacity: 0.8;
      }
    }
  }
}
</style>
