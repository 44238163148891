// 订单详情
<template>
  <div class="detail">
    <detail-header title="订单详情" />
    <div class="detail_main page_width">
      <order-info :order="order" />
      <div class="detail_main_service">
        <order-service
          v-if="order.id && activeService"
          :order="order"
          :active-service.sync="activeService">
        </order-service>

        <div v-if="showFinishBtn" class="tw-mt-20px">
          <el-button
            type="primary"
            size="small"
            @click="onFinishOrder">
            确认完结
          </el-button>
        </div>

        <div v-if="showConfirmBtn" class="tw-mt-20px">
          <el-button
            v-if="userInfo.id === order.sellerId"
            type="primary"
            size="small"
            @click="onConfirmOrder('confirmed')">
            确认订单
          </el-button>
          <el-button
            size="small"
            @click="onConfirmOrder('cancelled')">
            取消订单
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

import orderInfo from './order-info'
import orderService from './order-service'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    orderInfo,
    orderService
  },
  data () {
    return {
      activeService: '',
      order: {}
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    showFinishBtn () {
      // 当前用户是当前订单的买家且订单已确认未完成
      return this.userInfo.id === this.order.buyerId && [globalVar.ORDER_STATUS_3, globalVar.ORDER_STATUS_1].indexOf(this.order.logicStatus) !== -1
    },
    showConfirmBtn () {
      // 服务订单在已下单后需要被卖家确认或取消
      return this.order.orderType === 'service' && this.order.logicStatus === globalVar.ORDER_STATUS_0
    }
  },
  mounted () {
    this.getOrderDetail()
  },
  methods: {
    getOrderDetail (id = this.id, reload = false) {
      api.getOrderDetail(id).then(res => {
        if (res.data.code === 0) {
          this.order = res.data.data

          const userId = this.userInfo.id

          if (!reload) {
            if (userId === this.order.buyerId) {
              this.activeService = '0'
            } else {
              let activeIndex = 0

              for (let index = 0; index < this.order.tabs.length; index++) {
                const element = this.order.tabs[index]

                if (element.orgId === userId) {
                  activeIndex = index
                  break
                }
              }

              this.activeService = activeIndex.toString()
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onFinishOrder () {
      this.$confirm('确认要完成此订单吗？', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.finishOrder(this.order.id).then(res => {
          if (res.data.code === 0) {
            this.$message.success('订单已完结')
            this.getOrderDetail(this.id, true)
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('请求出错')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    onConfirmOrder (type) {
      this.$confirm('请确认你的操作!', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.confirmServiceOrder(this.order.id, type).then(res => {
          if (res.data.code === 0) {
            this.$message.success('操作成功')
            this.getOrderDetail(this.id, true)
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('请求出错')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 20px 0 38px 0;
    &_service {
      margin-top: 40px;
    }
  }
}
</style>
